/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import React, { Fragment, useState, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { styled } from '@compiled/react';
import { FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import ShortcutIcon from '@atlaskit/icon/core/link-external';
import { ModalTransition } from '@atlaskit/modal-dialog';

import type {
	FallbackComponentLegacyContentQuery as FallbackComponentLegacyContentQuery$data,
	FallbackComponentLegacyContentQueryVariables as FallbackComponentLegacyContentQuery$variables,
} from './__types__/FallbackComponentLegacyContentQuery';
import { LegacyContentModal } from './LegacyContentModal';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledContainer = styled.span({
	display: 'flex',
	padding: `${token('space.150')} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'> span:first-of-type': {
		paddingRight: token('space.100'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentSkeleton = styled.div({
	margin: '0 auto',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonContainer = styled.div({
	display: 'flex',
	padding: `${token('space.150')} 0 ${token('space.200')} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		paddingRight: token('space.100'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentSkeletonLine = styled.div<CommentSkeletonLineProps>({
	backgroundColor: token('color.skeleton'),
	borderRadius: '3px',
	height: '12px',
	marginBottom: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: `${(props: CommentSkeletonLineProps) => props.width}%`,
});

type FallbackComponentProps = {
	contentId?: string;
};

type CommentSkeletonLineProps = {
	width: number;
};

export const FallbackComponent = ({ contentId }: FallbackComponentProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const { data, loading } = useQuery<
		FallbackComponentLegacyContentQuery$data,
		FallbackComponentLegacyContentQuery$variables
	>(
		gql`
			query FallbackComponentLegacyContentQuery($contentId: ID!) {
				content(id: $contentId) {
					nodes {
						id
						body {
							view {
								value
								webresource {
									keys
								}
							}
						}
						history {
							createdBy {
								... on KnownUser {
									accountId
								}
								... on User {
									accountId
								}
								profilePicture {
									path
								}
								displayName
							}
						}
						type
					}
				}
			}
		`,
		{
			variables: { contentId: contentId ?? '' },
		},
	);
	const buildUserObject = (data: any) => {
		const userId = data?.content?.nodes[0].history?.createdBy?.accountId;
		const avatarUrl = data?.content?.nodes[0].history?.createdBy?.profilePicture?.path;
		const displayName = data?.content?.nodes[0].history?.createdBy?.displayName;
		return {
			userId,
			avatarUrl,
			displayName,
		};
	};
	const handleCloseModal = useCallback(() => {
		setIsOpen(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentId]);
	const user = buildUserObject(data);
	const legacyContent = data?.content?.nodes?.[0]?.body?.view?.value ?? '';
	const webResourceKeys = data?.content?.nodes?.[0]?.body?.view?.webresource?.keys ?? [];
	if (loading) {
		return (
			<SkeletonContainer>
				<ShortcutIcon spacing="spacious" label="legacy-modal-shortcut" />
				<CommentSkeleton>
					<CommentSkeletonLine width={100} />
					<CommentSkeletonLine width={100} />
					<CommentSkeletonLine width={75} />
				</CommentSkeleton>
			</SkeletonContainer>
		);
	}
	return (
		<Fragment>
			<StyledContainer>
				<ShortcutIcon spacing="spacious" label="legacy-modal-shortcut" />
				<span>
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<a href="#" onClick={handleCloseModal}>
						<FormattedMessage
							id="comment-extension-handlers.fallback.action"
							defaultMessage="Click to view content"
							description="clickable link to display more content"
						/>
					</a>{' '}
					<FormattedMessage
						id="comment-extension-handlers.fallback.reason"
						// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
						// eslint-disable-next-line no-restricted-syntax
						defaultMessage="that can't display inline as it was created in a legacy version of
          Confluence."
						description="reason for why we cannot display the fallback content"
					/>
				</span>
			</StyledContainer>
			<ModalTransition>
				{isOpen && (
					<LegacyContentModal
						onClose={setIsOpen}
						content={legacyContent}
						/* @ts-ignore LegacyContentModal possibly has incorrect types. Should be string[] and not [] */
						webResources={webResourceKeys}
						user={user}
					/>
				)}
			</ModalTransition>
		</Fragment>
	);
};
