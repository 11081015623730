// Helper function to safely parse dates
export const getValidDate = (dateString: string | null | undefined): Date => {
	if (!dateString) {
		return new Date(0); // Return earliest possible date if missing
	}

	// Ensure UTC timezone. Some createdAt values are missing the UTC timezone identifier, and some have it
	const normalizedDate = dateString.endsWith('Z') ? dateString : `${dateString}Z`;

	// Try to parse the date, return earliest date if invalid
	const parsed = new Date(normalizedDate);
	return isNaN(parsed.getTime()) ? new Date(0) : parsed;
};
